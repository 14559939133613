import React from "react";
import NewsTile from "../tiles/NewsTile";
import NewsQuery from "../../../queries/NewsQuery";

const NEWS_PER_PAGE = 3;

const MainNews = () => {
  const newsFiles = NewsQuery().slice(0, NEWS_PER_PAGE);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }} className="my-2">
      {newsFiles.length > 0 &&
        newsFiles.map((news, index) => (
          <NewsTile key={index} file={news} isHome={true} />
        ))}
    </div>
  );
};

export default MainNews;
