import { Col, Row } from "react-bootstrap";

import ProjectTile from "../tiles/ProjectTile";
import ProjectsQuery from "../../../queries/ProjectsQuery";
import React from "react";

const PROJECTS_PER_PAGE = 5;

const MainProjects = () => {
  const firstProjects = ProjectsQuery().slice(0, PROJECTS_PER_PAGE);

  return (
    <div className="my-2">
      {firstProjects.length === PROJECTS_PER_PAGE && (
        <>
          <Row>
            <Col md={6} style={{ padding: "0 1em 0 0" }}>
              <div
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-easing="ease-out"
              >
                <ProjectTile
                  file={firstProjects.shift()}
                  isPortrait={false}
                  isHome={true}
                />
              </div>
              <div
                data-sal="slide-up"
                data-sal-duration="900"
                data-sal-easing="ease-out"
              >
                <ProjectTile
                  file={firstProjects.shift()}
                  isPortrait={false}
                  isHome={true}
                />
              </div>
            </Col>
            <Col md={6} style={{ padding: "0 1em 0 0" }}>
              <ProjectTile
                file={firstProjects.shift()}
                isPortrait={true}
                isHome={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} style={{ padding: "0 1em 0 0" }}>
              <div
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-easing="ease-out"
              >
                <ProjectTile
                  file={firstProjects.shift()}
                  isPortrait={false}
                  isHome={true}
                />
              </div>
            </Col>
            <Col md={6} style={{ padding: "0 1em 0 0" }}>
              <div
                data-sal="slide-up"
                data-sal-duration="900"
                data-sal-easing="ease-out"
              >
                <ProjectTile
                  file={firstProjects.shift()}
                  isPortrait={false}
                  isHome={true}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default MainProjects;
