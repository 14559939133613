import { graphql, useStaticQuery } from "gatsby";

export default function ProjectsQuery() {
  const mdProjects = useStaticQuery(graphql`
    query PQuery {
      allMarkdownRemark {
        totalCount
        edges {
          node {
            frontmatter {
              slug
              shortname
              sortorder
              location
              address
              type
              units
              sizes
              projectstatus
              date
              image
              shortname
              longname
              projectline
              finishedon
            }
            html
          }
        }
      }
    }
  `);
  return mdProjects.allMarkdownRemark.edges
    .map((f) => f.node)
    .filter(
      (p) =>
        typeof p.frontmatter.slug === "string" &&
        p.frontmatter.slug.startsWith("p")
    )
    .sort((a, b) => {
      const pA = a.frontmatter.sortorder,
        pB = b.frontmatter.sortorder;
      if (pA < pB) {
        return -1;
      }
      if (pA > pB) {
        return 1;
      }
      return 0;
    });
}
