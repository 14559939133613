import {
  ABOUT_PATH,
  ACQUISITIONS_PATH,
  HOME,
} from "../components/core/nav/NavigationItems";
import { Col, Container, Row } from "react-bootstrap";

import HomeLogo from "../assets/images/logos/newspace-logo.png";
import HomeShape from "../components/core/ux/HomeShape";
import Layout from "../components/core/Layout";
import { Link } from "gatsby";
import MainNews from "../components/core/ux/teasers/MainNews";
import MainProjects from "../components/core/ux/teasers/MainProjects";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => (
  <Layout page={HOME}>
    <img
      src={HomeLogo}
      alt="new.space Logo"
      width={250}
      className="home-logo"
    />
    <HomeShape isHome={true} />
    <Container>
      <h2 className="text-uppercase h-muted">new.space</h2>
      <h1 className="text-uppercase h bolder">Projektentwicklung</h1>
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">
          Hochwertige Wohnkonzepte für heute, morgen und übermorgen
        </h3>
      </div>
      <Row className="my-5">
        <Col lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <p lang="de" style={{ hyphens: "auto" }}>
              <strong>new.space</strong> ist Ihr anspruchsvoller
              Projektentwickler aus Kassel – mit Immobilienprojekten im gesamten
              Bundesgebiet. Gemeinsam mit Ihnen machen wir uns Gedanken über
              zukunftsfähige Konzepte für Senior Living, Student Living und
              zeitgemäße Wohnkonzepte. Dabei legen wir Wert auf Nachhaltigkeit,
              persönliche Freiheit sowie maximale Selbstbestimmung.
            </p>
          </div>
        </Col>
        <Col lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/final/OFFICE-6.jpg"}
              alt="new.space - Hochwertige Wohnkonzepte für heute, morgen und übermorgen"
              placeholder="blurred"
              className="grow"
              quality={100}
            />
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/final/OFFICE-5.jpg"}
              alt="new.space - Rundum-Know-how für Ihre Objekt-Realisierung"
              placeholder="blurred"
              className="grow"
              quality={100}
            />
          </div>
        </Col>
        <Col lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <p lang="de" style={{ hyphens: "auto" }}>
              Zusammen mit unseren Partnern realisieren wir diese Projekte nach
              den hohen ESG-Anlagekriterien. Sie profitieren von unserer
              langjährigen Erfahrung in der Standortanalyse, dem
              Grundstücksankauf, der Architektenplanung und Bauüberwachung sowie
              unserem sicheren Gespür bei Vermietung und Verkauf.
            </p>
          </div>
        </Col>
      </Row>
      <hr className="my-4" />
      <b>
        Suchen Sie das Rundum-Know-how für Ihre Objekt-Realisierung? Dann
        schreiben Sie uns oder rufen Sie uns an – Tel. +49 561 739 888 40,
        kontakt@newspace-ag.de
      </b>
      <hr className="my-4" />
      <br />
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">Verständnis. Vertrauen. Verbindlichkeit.</h3>
      </div>
      <Row>
        <Col lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <p lang="de" style={{ hyphens: "auto" }}>
              Die erfahrenen Mitarbeiter rund um Michael Kopeinigg wissen,
              worauf es ankommt. Sie verstehen die Anforderungen unserer Zeit
              und haben immer den Menschen im Blick. Wir vereinen stets
              ökonomische, aber auch ökologische und soziale Ziele. So sichern
              wir die Wertschöpfung der Immobilien und bieten Lebenskonzepte, in
              denen sich Menschen wohlfühlen. Lernen Sie das Team hinter{" "}
              <strong>new.space</strong> kennen.
            </p>
            <p>
              <Link to={ABOUT_PATH}>Mehr erfahren</Link>
            </p>
          </div>
        </Col>
        <Col lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/final/OFFICE-4.jpg"}
              alt="new.space - Verständnis. Vertrauen. Verbindlichkeit."
              placeholder="blurred"
              className="grow"
              quality={100}
            />
          </div>
        </Col>
      </Row>
      <br />
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">
          Intelligent geplant – und erfolgreich realisiert
        </h3>
      </div>
      <Row>
        <Col lg={12} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <p>
              <strong>new.space</strong> Projekte finden Sie im gesamten
              Bundesgebiet. Sie erkennen sie daran, dass sie mit Weitblick
              entwickelt sowie nachhaltig geplant und gebaut wurden und werden.
              Gerne zeigen wir Ihnen, was wir schon realisiert haben – und
              welche Projekte bald fertiggestellt werden.
            </p>
          </div>
        </Col>
      </Row>
      <div className="mb-5">
        <MainProjects />
      </div>
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">
          <strong>new.space</strong> sucht attraktive Standorte
        </h3>
      </div>
      <Row>
        <Col lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/final/OFFICE-7.jpg"}
              alt="new.space sucht attraktive Standorte"
              placeholder="blurred"
              className="grow"
              quality={100}
            />
          </div>
        </Col>
        <Col lg={7} className="vertical-middle">
          <div data-sal="slide-up" data-sal-duration="900">
            <p lang="de" style={{ hyphens: "auto" }}>
              Wir sind bereit, in die Zukunft zu investieren. Bevorzugt suchen
              wir B- und C-Standorte, für die wir zukunftsfähige Konzepte
              entwickeln. Mit klar kalkulierbarer Renditeerwartung. Mit
              verantwortungsvollem CO<sub>2</sub>-Fußabdruck. Mit reizvoller
              Perspektive für die Menschen, die dort künftig leben.
            </p>
            <p>
              <Link to={ACQUISITIONS_PATH}>Mehr erfahren</Link>
            </p>
          </div>
        </Col>
      </Row>
      <br />
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">
          Neues von und über <strong>new.space</strong>
        </h3>
      </div>
      <div className="mb-5">
        <MainNews />
      </div>
    </Container>
  </Layout>
);

export default IndexPage;
