import { NULL_STRING } from "../../../queries/JobsQuery";
import { PROJECTS_PATH } from "../../nav/NavigationItems";
import ProjectCard from "./ProjectCard";
import React from "react";

const ProjectTile = ({ file, isPortrait, isHome }) => {
  const [hover, setHover] = React.useState(false);
  const showTile = file !== undefined;
  const meta = showTile ? file.frontmatter : undefined,
    image = showTile
      ? [NULL_STRING, "/media/"].includes(meta.image)
        ? ""
        : meta.image
      : undefined,
    url = showTile
      ? isHome
        ? PROJECTS_PATH.slice(1) + meta.slug
        : meta.slug
      : "";

  return (
    <>
      {showTile && (
        <span
          className="tile"
          key={meta.slug}
          style={{
            padding: "1em",
          }}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onFocus={() => setHover(true)}
          onBlur={() => setHover(false)}
          role="button"
          tabIndex="-1"
        >
          <ProjectCard
            isPortrait={isPortrait}
            url={url}
            image={image}
            hover={hover}
            shortname={meta.shortname}
          />
        </span>
      )}
    </>
  );
};

export default ProjectTile;
